<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="searchBar"
    >
      <h2
        class="headline font-weight-medium"
        @click="$refs.buttonSearch.click()"
      >
        Template List
      </h2>
      <v-spacer class="hidden-xs-only" />
      <x-formgroup
        v-model="scheme.valid"
        class="d-box-md-and-up w100-md-and-down"
      >
        <x-textfield
          v-model="scheme.templateName.value"
          v-bind="scheme.templateName"
          class="small"
          @keyup.enter="() => $refs.buttonSearch.click()"
        />
        <x-datetime-picker
          v-bind="scheme.datetimePicker"
          :start-date="updateAfter"
          :end-date="updateBefore"
          time-format="HH:mm"
          class="small"
          @onChange="updateDateFilter"
        />

        <x-button
          ref="buttonSearch"
          v-bind="buttonSearch"
          class="btnSecondary btnXsBlock"
        />
      </x-formgroup>
    </v-layout>
    <!-- END header -->
    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout
          row
          wrap
        >
          <span
            class="w100-md-and-down"
            @click="openCreateTemplate"
          >
            <x-button
              v-if="authenticate('TemplateCreate')"
              v-bind="buttonCreateTemplate"
              class="btnPrimary btnXsBlock"
            />
          </span>

          <v-spacer class="hidden-xs-only" />

          <!-- BEGIN filter -->
          <x-formgroup class="table-filters d-box-md-and-up w100-md-and-down">
            <span class="mr-2 labelStyle">Filter</span>
            <x-select-filter
              v-model="filterType.value"
              v-bind="filterType"
              :items="filterTypeOptions"
              class="small"
              @change="() => fetchTemplateList()"
            />
          </x-formgroup>
          <!-- END filter -->
        </v-layout>

        <hint>
          Once a template has been assigned to a task, the template will no
          longer be modifiable
        </hint>

        <x-table
          v-bind="tableScheme"
          class="mt-3 templateList"
        />

        <pagination
          ref="pager"
          :query="query"
          :fetch="$Template.fetchTemplateList"
          :callback="pagerCallback"
        />
      </div>
    </div>
    <!-- END content -->
    <x-dialog
      v-model="dialogCreateTemplate.value"
      v-bind="dialogCreateTemplate"
    >
      <h3
        slot="slot-header"
        class="slot-header title"
      >
        Select Template Type
      </h3>
      <span />
      <div
        slot="slot-btn"
        class="create-template"
      >
        <v-layout>
          <v-flex class="justify-space-between">
            <v-btn
              v-for="channel of taskChannels"
              :key="channel.id"
              depressed
              class="btnSecondary v-btn"
              @click="channel.action"
            >
              <v-icon>{{ channel.icon }}</v-icon>
              <p>{{ $t(`Task.channel.${channel.id}`) }}</p>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </x-dialog>

    <div
      :is="previewComponent"
      v-if="previewChannelType"
      :value="true"
      :title="previewItem.name"
      v-bind="previewItem"
      @confirm="previewChannelType = null"
    />
  </div>
</template>

<script>
import { formatDatetimeFromISOString, localDateToISOString } from '@/utils/date'
import { authenticate } from '@/auth/allowAuthMapping.js'
import EdmPreview from '../components/EdmPreview'
import SmsPreviewDialog from '../components/SmsPreviewDialog'
import AppPushPreviewDialog from '../components/AppPushPreviewDialog'
import Hint from '@/components/Hint'
import Pagination from '@/components/Pagination'
import { getCompanyConfig } from '@/const'

export default {
  components: {
    Pagination,
    Hint
  },
  data () {
    return {
      query: {},
      previewItem: null,
      previewChannelType: null,
      updateAfter: undefined,
      updateBefore: undefined,

      dialogCreateTemplate: {
        value: false,
        closeSign: true,
        maxWidth: '640px'
      },

      // BEGIN search
      scheme: {
        templateName: {
          type: 'text',
          name: 'templateName',
          placeholder: 'Template Name',
          value: null
        },

        datetimePicker: {
          singleDate: false,
          alignRight: true,
          placeholder: 'select'
        }
      },

      buttonSearch: {
        content: 'Search',
        size: 'small',
        click: () => this.fetchTemplateList()
      },
      // END search

      // BEGIN filter
      filterType: {
        placeholder: 'All Type',
        value: null
      },
      // END filter

      buttonCreateTemplate: {
        content: 'Create Template',
        size: 'medium',
        prependIcon: 'add'
      },

      tableScheme: {
        multiSelectable: false,
        headers: [
          { value: 'no', text: 'No' },
          { value: 'formattedType', text: 'Type' },
          { value: 'id', text: 'Template ID' },
          {
            value: 'name',
            text: 'Template Name'
          },
          { value: 'formattedCreateDate', text: 'Create Date' },
          { value: 'formattedUpdateDate', text: 'Updated' },
          { value: 'action', text: 'Actions' }
        ],
        items: [],
        actions: [
          {
            content: 'Preview',
            outline: true,
            render: template => ['SMS', 'EDM'].includes(template.type),
            click: item => {
              this.previewItem = item
              this.previewChannelType = item.type
              if (item.type === 'EDM' && item.content) {
                this.$store.builderProperty.rowSectionList = JSON.parse(
                  item.content
                )
              }
            }
          },
          {
            content: 'Copy',
            outline: true,
            render: () => authenticate('TemplateCreate'),
            click: this.cloneTemplate
          },
          {
            content: 'Edit',
            outline: true,
            render: template =>
              authenticate('TemplateEdit') && template.taskCount === 0,
            click: item => {
              switch (item.type) {
                case 'SMS':
                  return this.$router.push({
                    name: 'TemplateEditSms',
                    params: { templateId: item.id }
                  })
                case 'EDM':
                  return this.$router.push({
                    name: 'TemplateEditEdm',
                    params: { id: item.id }
                  })
                case 'APP_PUSH':
                  return this.$router.push({
                    name: 'TemplateEditAppPush',
                    params: { id: item.id }
                  })
              }
            }
          },
          {
            content: 'Delete',
            outline: true,
            class: 'actionWarning',
            render: template =>
              authenticate('TemplateDelete') && template.taskCount === 0,
            click: this.deleteTemplateIntent
          }
        ]
      }
    }
  },
  computed: {
    previewComponent () {
      switch (this.previewChannelType) {
        case 'APP_PUSH':
          return AppPushPreviewDialog
        case 'EDM':
          return EdmPreview
        case 'SMS':
        default:
          return SmsPreviewDialog
      }
    },
    taskChannels () {
      const { taskChannels } = getCompanyConfig({ env: this.$store.env })
      const config = {
        SMS: {
          order: 2,
          icon: 'chat',
          action: () => this.goSmsCreate()
        },
        EDM: {
          order: 1,
          icon: 'email',
          action: () => this.goEdmCreate()
        },
        APP_PUSH: {
          order: 3,
          icon: 'phone_android',
          action: () => this.goAppPushCreate()
        }
      }
      return taskChannels
        .filter(channel => config[channel])
        .map(channel => ({
          id: channel,
          ...config[channel]
        }))
        .sort((a, b) => a.order - b.order)
    },
    filterTypeOptions () {
      return [
        {
          value: null,
          text: 'All'
        }
      ].concat(
        this.taskChannels.map(({ id }) => ({
          value: id,
          text: this.$t(`Task.channel.${id}`)
        }))
      )
    }
  },
  async mounted () {
    await this.fetchTemplateList()
  },
  methods: {
    updateDateFilter: function (data) {
      this.updateAfter = data.startDate
      this.updateBefore = data.endDate
    },
    openCreateTemplate () {
      this.dialogCreateTemplate.value = true // open Dialog
    },
    pagerCallback ({ templates }) {
      this.tableScheme.items = templates.map((template, ind) => {
        template.campaign = {}
        if (!this.$refs.pager) return
        return {
          ...template,
          formattedType: this.$t(`Task.channel.${template.type}`),
          no: this.$refs.pager.offset + ind + 1,
          formattedCreateDate: formatDatetimeFromISOString(template.createTime),
          formattedUpdateDate: formatDatetimeFromISOString(template.updateTime)
        }
      })
    },
    async fetchTemplateList () {
      this.query = {
        type: this.filterType.value,
        name: this.scheme.templateName.value,
        updateAfter: this.updateAfter && localDateToISOString(this.updateAfter),
        updateBefore:
          this.updateBefore && localDateToISOString(this.updateBefore)
      }
    },
    async cloneTemplate (template, e) {
      e.stopPropagation()
      this.$store.loading = true
      try {
        let name = template.name
        const match = name.match(/(^.+)\scopy(?:\s(\d+))?$/)
        if (match && match[1]) {
          name = `${match[1]} copy ${(Number(match[2]) || 0) + 1}`
        } else {
          name = `${name} copy`
        }
        await this.$Template.createTemplate({
          name: name,
          content: template.content,
          type: template.type,
          subject: template.subject
        })
        this.$store.successNotification = 'Successfully cloned'
        await this.$refs.pager.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
      return false
    },
    async deleteTemplateIntent (template, e) {
      e.stopPropagation()
      this.$store.confirmDialog.push({
        message: 'Are you sure to remove this template? This cannot be undone.',
        onConfirm: () => this.deleteTemplate(template.id)
      })
      return false
    },
    async deleteTemplate (templateId) {
      this.$store.loading = true
      try {
        await this.$Template.deleteTemplate({
          id: templateId
        })
        this.$store.successNotification =
          'Template has been successfully removed'
        await this.$refs.pager.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    authenticate (modelAction) {
      return authenticate(modelAction)
    },
    goEdmCreate () {
      this.$router.push({
        name: 'TemplateCreateEdm'
      })
    },
    goSmsCreate () {
      this.$router.push({
        name: 'TemplateCreateSms'
      })
    },
    goAppPushCreate () {
      this.$router.push({
        name: 'TemplateCreateAppPush'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.create-template {
 >>> .v-btn {
    height: auto;
    width: 120px;
    padding: 12px 15px;
    .v-btn__content {
      display: block;
      i.v-icon {
        font-size: 30px;
      }
      p {
        margin: 7px 0 0;
        font-size: $fs-regular;
        letter-spacing: 1.2px;
        font-weight: 700;
      }
    }
  }
}
</style>
