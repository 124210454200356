<template>
  <tr>
    <td>
      <div class="contentBox fixHeight">
        &nbsp;
      </div>
      <table
        class="contentBox sectionWrap active"
        @focusout.stop="saveContent"
      >
        <tr>
          <td
            ref="titleSection"
            contenteditable="true"
            class="focusElement"
            @click="onClick"
            v-html="greetingText"
          />
          <VariableSelector
            v-if="isEditing"
            :selected.sync="selectedVariable"
            :value="isEditing"
            :position="toolBoxPosition"
          />
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import {
  sectionType,
  DEFAULT_SECTION_EDM_TITLE
} from '@/components/SectionUI/const/sections'
import DOMPurify from 'dompurify'
import VariableSelector from '@/components/SectionUI/tools/VariableSelector'

export default {
  name: 'SectionTitle',
  components: { VariableSelector },
  props: {
    titleSection: {
      type: Object,
      required: true,
      validator: val => {
        return (
          val.type === sectionType.HeaderElement &&
          typeof val.id !== 'undefined'
        )
      }
    }
  },
  data () {
    return {
      isEditing: false,
      toolBoxPosition: {
        x: 0,
        y: 0
      },
      html: '' // This forces UI update when auto update failed with Old Templates which doesn't have TitleSection
    }
  },
  computed: {
    greetingText: {
      get () {
        return (
          this.html || this.titleSection.data.text || DEFAULT_SECTION_EDM_TITLE
        )
      },
      set (val) {
        this.$emit(
          'update',
          Object.assign(this.titleSection, {
            data: {
              text: DOMPurify.sanitize(val, {
                ALLOWED_ATTR: ['contenteditable'],
                ALLOWED_TAGS: ['span']
              })
            }
          })
        )
      }
    },
    selectedVariable: {
      get () {
        const match = this.greetingText.match(/<span.+{{(\w+)}}<\/span>/)
        return (match && match[1]) || ''
      },
      set (value) {
        let html = this.$refs.titleSection.innerHTML
        html = html.replace(/(<span.+{{)(\w+)(}}<\/span>)/, `$1${value}$3`)
        this.greetingText = html
        this.html = html
      }
    }
  },
  methods: {
    onClick (e) {
      const target = e.target
      if (target.tagName === 'SPAN' && /{{\w+}}/.test(target.textContent)) {
        this.isEditing = true
        this.toolBoxPosition = {
          x: target.offsetLeft + 10,
          y: target.offsetTop - 47
        }
      } else {
        this.isEditing = false
      }
    },
    saveContent (e) {
      if (!e || (e.relatedTarget && e.relatedTarget.tagName !== 'BUTTON')) {
        this.isEditing = false
      }
      this.greetingText = this.$refs.titleSection.innerHTML || ' '
    }
  }
}
</script>

<style lang="stylus" scoped>
.contentBox {
  padding: 0 14px;
  border: none;

  td {
    padding: 0;
    font-size: 22px;
    font-weight: bold;
    color: #444;
  }
}

.sectionWrap {
  border-top: none;
}

.fixHeight {
  height: 26px;
}
</style>
