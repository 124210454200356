<template>
  <table
    class="contentBox"
    @mouseleave="cancel"
    @dragleave.stop="() => {}"
  >
    <tr>
      <td class="tdSpace" />
    </tr>
    <tr>
      <td class="body">
        <span
          :id="textElementID"
          ref="editContent"
          v-selection
          :contenteditable="true"
          class="lbleditable focusElement"
          tabindex="0"
          @selected="handleSelected"
          @blur="saveContent"
          v-html="getTextData"
        />

        <div
          class="activeIcon position-fixed"
          :style="activeIconStyle"
        >
          <x-button
            v-bind="buttonEdmLinkto"
            :click="openEditLinkDialog"
            class="btnLink"
          />
        </div>
        <VariableSelector
          :selected.sync="selectedVariable"
          :value="tools.variable.isShow"
          :position="tools.variable.position"
        />
        <edit-link-dialog
          v-if="linkDialog.value"
          v-bind="linkDialog"
          @confirm="onLinkConfirmed"
          @remove="onLinkRemoved"
          @close="linkDialog.value = false"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import {
  DEFAULT_SECTION_TEXT,
  buttonEdmLinkto,
  builderEvent
} from '@/components/SectionUI/const/sections'
import EditLinkDialog from '@/modules/Template/components/EditLinkDialog'
import DOMPurify from 'dompurify'
import company from '@/mixins/company'
import selection from '@/directives/selection'
import VariableSelector from '@/components/SectionUI/tools/VariableSelector'

export default {
  name: 'TextElement',
  components: { EditLinkDialog, VariableSelector },
  directives: { selection },
  mixins: [company],
  props: {
    handledSection: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      linkDialog: {
        value: false
      },
      buttonEdmLinkto,
      mouseUpX: 0,
      mouseUpY: 0,
      tools: {
        link: {
          isShow: false,
          isEdit: false,
          link: '{}'
        },
        variable: {
          isEdit: false,
          isShow: false,
          selected: null,
          position: {
            x: 0,
            y: 0
          }
        }
      }
    }
  },
  computed: {
    textElementID () {
      return 'txt-' + this.handledSection.id
    },
    getTextData () {
      return (
        (this.handledSection &&
          this.handledSection.data &&
          this.handledSection.data.text) ||
        DEFAULT_SECTION_TEXT
      )
    },
    activeIconStyle () {
      return {
        display: this.tools.link.isShow ? 'block' : 'none',
        top: this.mouseUpY + 'px',
        left: this.mouseUpX + 'px'
      }
    },
    selectedVariable: {
      get () {
        return this.tools.variable.selected
      },
      set (x) {
        this.insertVariable(x)
      }
    }
  },
  mounted () {
    this.$root.$on(builderEvent.sectionSaveContent, () => {
      this.saveContent()
    })
  },
  methods: {
    cancel () {
      this.tools.link.isShow = false
      this.tools.variable.isShow = false
    },
    saveContent () {
      const html = this.$refs.editContent.innerHTML
      this.handledSection.data.text = DOMPurify.sanitize(html, {
        ADD_ATTR: ['target', 'contenteditable']
      })
    },
    handleSelected (e) {
      this.showVariableTool(e)
      this.showLinkTool(e)
    },
    onLinkConfirmed (_, link) {
      const detail = {
        tag: 'A',
        attrs: {
          'data-link': JSON.stringify(link),
          href: link.url || '#',
          target: '_blank',
          style: `color: ${this.company.style.color.secondary}`
        }
      }
      const keyword = this.tools.link.isEdit ? 'updateNode' : 'insertNode'
      this.$refs.editContent.dispatchEvent(new CustomEvent(keyword, { detail }))
      this.saveContent()
    },
    onLinkRemoved () {
      this.$refs.editContent.dispatchEvent(new CustomEvent('updateNode'))
      this.saveContent()
    },
    openEditLinkDialog () {
      const { isEdit, link } = this.tools.link
      this.linkDialog.link = isEdit
        ? link
        : { url: this.company.defaultLink.officialUrl }
      this.linkDialog.value = true
    },
    showLinkTool (e) {
      const { event, selection } = e.detail

      if (!selection.focusNode.isEqualNode(selection.anchorNode)) {
        return console.warn(
          'Set link across multiple DOM is currently not allowed.'
        )
      }
      const isCreate = !selection.isCollapsed
      const isEdit = selection.baseNode.parentNode.tagName === 'A'
      if (isCreate || isEdit) {
        this.mouseUpX = event.clientX - 50
        this.mouseUpY = event.clientY - 50
        this.tools.link = {
          isShow: true,
          isEdit
        }
        if (isEdit) {
          const node = selection.baseNode.parentNode
          const linkJson = node.dataset.link
          const href = node.getAttribute('href')
          this.tools.link.link = linkJson ? JSON.parse(linkJson) : { url: href }
        }
      } else {
        this.tools.link.isShow = false
      }
    },
    showVariableTool (e) {
      const { event } = e.detail
      this.tools.variable.isShow = true
      const isEdit = event.target.dataset.type === 'variable'
      this.tools.variable.isEdit = isEdit
      if (isEdit) {
        this.tools.variable.position = {
          x: event.target.offsetLeft,
          y: event.target.offsetTop - 35
        }
      } else {
        const parentRect = this.$refs.editContent.getBoundingClientRect()
        this.tools.variable.position = {
          x: parentRect.width - 100,
          y: -20
        }
      }
    },
    insertVariable (text) {
      const detail = {
        tag: 'SPAN',
        child: `{{${text}}}`,
        attrs: {
          contenteditable: false,
          'data-type': 'variable'
        }
      }

      const keyword = this.tools.variable.isEdit ? 'updateNode' : 'insertNode'
      this.$refs.editContent.dispatchEvent(new CustomEvent(keyword, { detail }))
      this.saveContent()
    }
  }
  // beforeDestroy() {
  //   this.$root.$off(builderEvent.sectionSaveContent)
  // }
}
</script>

<style lang="stylus" scoped>
.contentBox {
  position: relative;
  .body {
    font-size: 16px;
    color: #444;

    a {
      color: $cashalo-secondary;
      text-decoration: none;
    }

    .lbleditable {
      word-break: break-word;
      min-height: 30px;
      display: block;
    }
  }
}

.activeIcon {
  z-index: 20;
}
</style>
