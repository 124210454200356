<template lang="pug">
  Form(:template="template" @submit="createTemplate")
</template>

<script>
import Form from '../components/AppPushForm'

export default {
  components: {
    Form
  },
  data () {
    return {
      template: {}
    }
  },
  methods: {
    async createTemplate (data) {
      this.$store.loading = true
      try {
        await this.$Template.createTemplate(data)
        this.$store.successNotification = 'Template successfully created'

        this.$router.go(-1)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>
