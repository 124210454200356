<template>
  <tr>
    <td>
      <table class="contentBox">
        <tr>
          <td />
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'SectionBottom'
}
</script>

<style lang="stylus" scoped>
.contentBox {
  height: 24px;
  border: none;
}
</style>
