<template>
  <table
    class="contentBox"
    @mouseenter="isEditing = true"
    @mouseleave="isEditing = false"
    @dragleave.stop="() => {}"
  >
    <tr>
      <td class="tdSpace" />
    </tr>
    <tr>
      <td>
        <span :class="['contentWrap', { active: isEditing }]">
          <a
            :id="`btn-${handledSection.id}`"
            ref="editContent"
            href="#"
            :contenteditable="true"
            class="focusElement"
            @blur="saveContent"
          >{{ buttonText }}</a>

          <!-- BEGIN activeIcon -->
          <div
            v-if="isEditing"
            class="activeIcon"
          >
            <span class="clickBtn">
              <x-button
                v-bind="buttonEdmLinkto"
                :click="openEditLinkDialog"
                class="btnLink"
              />
            </span>
          </div>
        </span>
        <edit-link-dialog
          v-if="linkDialog.value"
          v-bind="linkDialog"
          @confirm="(_, link) => updateLink(link)"
          @close="linkDialog.value = false"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import { DEFAULT_BUTTON_TITLE, buttonEdmLinkto } from '../const/sections'
import EditLinkDialog from '@/modules/Template/components/EditLinkDialog'
import DOMPurify from 'dompurify'
import company from '@/mixins/company'

export default {
  name: 'ButtonElement',
  components: { EditLinkDialog },
  mixins: [company],
  props: {
    handledSection: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditing: false,
      buttonEdmLinkto,
      linkDialog: {
        value: false,
        deletable: false
      }
    }
  },
  computed: {
    buttonText () {
      return (
        (this.handledSection.data && this.handledSection.data.text) ||
        DEFAULT_BUTTON_TITLE
      )
    }
  },
  mounted () {
    const { defaultLink, style } = this.company
    this.handledSection.data.color = style.color.primary
    this.linkDialog.link = {
      url: defaultLink.officialUrl,
      ...this.handledSection.data.link
    }
  },
  methods: {
    openEditLinkDialog () {
      this.linkDialog.value = true
      this.linkDialog.link = this.handledSection.data.link
    },
    saveContent () {
      const html = this.$refs.editContent.innerText
      this.handledSection.data.text = DOMPurify.sanitize(html)
    },
    updateLink (link) {
      this.handledSection.data.link = link
    }
  }
}
</script>

<style lang="stylus" scoped>
.contentBox {
  td {
    width: 48px;
    text-align: center;

    a {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      background-color: $cashalo-primary;
      padding: 14px 24px;
      text-decoration: none;
      border-radius: 4px;
      display: inline-block;
    }
  }
}
</style>

<style lang="stylus">
.theme--3 .contentBox td [id^='btn-'] {
  background-color: $finmas-primary;
}
.theme--4 .contentBox td [id^='btn-'] {
  background-color: $finizi-primary;
}
</style>
