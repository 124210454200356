// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EditLinkDialog_dialog_V9P5X .v-dialog .slot-header {\n  padding: 20px 50px 0 20px;\n}\n.EditLinkDialog_radio-item_3sbvg .EditLinkDialog_content_2iRTg {\n  margin-left: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"dialog": "EditLinkDialog_dialog_V9P5X",
	"radio-item": "EditLinkDialog_radio-item_3sbvg",
	"content": "EditLinkDialog_content_2iRTg"
};
module.exports = exports;
