<template>
  <tr>
    <td
      ref="wrapper"
      :class="{ sectionWrap: true, active: onEditing }"
      @mouseleave="onMouseLeaveHandle"
      @mouseenter="onMouseEnterHandle"
      @dragover="onDragOverHandle"
      @dragleave="onDragLeaveHandle"
      @drop="onDropHandle"
    >
      <!--add section wrap class here-->
      <div
        v-if="onDragOver && insertBefore"
        class="dropHere sectionWrap active"
      >
        Drop Here
      </div>

      <function-bar-section
        v-if="onEditing"
        :style="`padding-top: ${onDragOver && insertBefore ? 150 : 0}px`"
        :handled-section="sectionObj"
      />

      <component
        :is="sectionComponent"
        :handled-section="sectionObj"
      />

      <div
        v-if="onDragOver && !insertBefore"
        class="dropHere sectionWrap active"
      >
        Drop Here
      </div>
    </td>
  </tr>
</template>

<script>
import FunctionBarSection from './FunctionBarSection'
import { rowSectionObj } from './const/sections'
import {
  TextElement,
  ImageElement,
  ButtonElement,
  ImageFullWidth,
  Image2Column,
  Image3Column
} from '@/components/SectionUI/SectionItems'

export default {
  name: 'DragHandle',
  components: {
    FunctionBarSection
  },
  props: {
    link: {
      type: String,
      default: '#'
    },
    sectionObj: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      onEditing: true, // default false
      onDrop: false,
      onDragOver: false,
      insertBefore: false
    }
  },
  computed: {
    sectionComponent () {
      return {
        TextElement,
        ImageElement,
        ButtonElement,
        ImageFullWidth,
        Image2Column,
        Image3Column
      }[this.sectionObj.type]
    }
  },
  methods: {
    onMouseEnterHandle (event) {
      this.onEditing = true
    },
    onMouseLeaveHandle (event) {
      this.onEditing = true // default false
    },
    onDragOverHandle (event) {
      if (
        this.$store.builderProperty.draggingSectionId === this.sectionObj.id
      ) {
        this.onDropOver = false
        return
      }
      this.onDragOver = true
      this.insertBefore =
        this.$refs.wrapper.getBoundingClientRect().top +
          this.$refs.wrapper.clientHeight / 2 >
        event.clientY
    },
    onDragLeaveHandle (event) {
      this.onDragOver = false
    },
    onDropHandle (event) {
      this.onDragOver = false

      const insertIndex = this.$store.builderProperty.rowSectionList.findIndex(
        section => section.id === this.sectionObj.id
      )

      const newSectionType = event.dataTransfer.getData('type')
      if (newSectionType) {
        // Insert new section
        const insertSection = rowSectionObj(newSectionType)
        this.$store.builderProperty.rowSectionList.splice(
          insertIndex + (this.insertBefore ? 0 : 1),
          0,
          insertSection
        )
      } else {
        // Rearrange existed sections
        const selectedSectionJSON = event.dataTransfer.getData('handledSection')
        const insertSection = JSON.parse(selectedSectionJSON)
        const currentIndex = this.$store.builderProperty.rowSectionList.findIndex(
          section => section.id === insertSection.id
        )
        this.$store.builderProperty.rowSectionList.splice(currentIndex, 1)
        this.$store.builderProperty.rowSectionList.splice(
          insertIndex +
            (this.insertBefore ? 0 : 1) +
            (currentIndex <= insertIndex ? -1 : 0),
          0,
          insertSection
        )
      }
      this.$store.builderProperty.draggingSectionId = null
    }
  }
}
</script>

<style lang="stylus" scoped></style>
