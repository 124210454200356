<template>
  <div
    :class="['dvupimg', 'contentWrap', { active: isEditing }]"
    @mouseenter="isEditing = true"
    @mouseleave="isEditing = false"
  >
    <label
      v-if="uploading"
      class="lblUploading"
    >
      <x-spin v-bind="spinScheme" />
      <p class="caption">Uploading...</p>
    </label>

    <img
      ref="image"
      :src="getImageUrl"
      @dragstart.stop.prevent="() => {}"
    >

    <!-- BEGIN activeIcon -->
    <div
      v-show="isEditing"
      class="activeIcon"
    >
      <span class="fileinputContainer clickBtn">
        <x-button
          v-bind="buttonChangeImg"
          class="btnChangeImg"
        />
        <input
          type="file"
          accept="image/png, image/jpeg, image/gif"
          @change="onfileChange"
        >
      </span>
      <span>
        <slot name="actions" />
      </span>
    </div>
  </div>
</template>

<script>
import { buttonChangeImg } from './const/sections'
import { convertFileToBase64, imgUpload } from '@/apis/Upload'
import company from '@/mixins/company'

export default {
  name: 'ItemImageUpload',
  mixins: [company],
  props: {
    uploadToServer: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: null
    },
    sectionId: {
      type: String,
      default: ''
    },
    minWidth: {
      type: [Number, Boolean],
      default: false
    },
    minHeight: {
      type: [Number, Boolean],
      default: false
    },
    maxWidth: {
      type: [Number, Boolean],
      default: false
    },
    maxHeight: {
      type: [Number, Boolean],
      default: false
    },
    width: {
      type: Number,
      default: 600
    }
  },
  data () {
    return {
      uploading: false,
      buttonChangeImg,
      isEditing: false,

      spinScheme: {
        name: 'ball-spin-fade',
        color: '#FFF',
        size: '10px'
      },
      currentImageUrl: this.imageUrl
    }
  },
  computed: {
    getImageUrl () {
      if (this.imageUrl) return this.imageUrl
      const section = this.$store.builderProperty.rowSectionList.find(
        sec => sec.id === this.sectionId
      )
      return (
        (section && section.data && section.data.img) ||
        this.company.defaultImage.banner
      )
    }
  },
  watch: {
    currentImageUrl (val) {
      const checkImage = async (tries = 3) => {
        this.uploading = true
        this.$refs.image.src = val
        this.$refs.image.onerror = () => {
          setTimeout(() => checkImage(tries - 1), 1000)
        }
        this.$refs.image.onload = () => {
          this.uploading = false
        }
      }
      checkImage(10)
    }
  },
  methods: {
    onfileChange (event) {
      const file = event.target.files[0]

      const imgforSizeDetect = new Image()
      const _URL = window.URL || window.webkitURL
      imgforSizeDetect.onload = (_this =>
        async function () {
          const isValid =
            (!_this.maxWidth || this.width <= _this.maxWidth) &&
            (!_this.minWidth || this.width >= _this.minWidth) &&
            (!_this.maxHeight || this.height <= _this.maxHeight) &&
            (!_this.minHeight || this.height >= _this.minHeight)
          if (!isValid) {
            return (_this.$store.errorNotification = composeErrorMessage(_this))
          }
          const isSizeValid = file.size <= 5 * 10 ** 6
          if (!isSizeValid) {
            return (_this.$store.errorNotification =
              'Image size should be smaller than 5MB')
          }

          _this.uploading = true
          const fileType = file.type.match(/^image\/(.+)$/)[1]
          const base64Url = await convertFileToBase64(file)
          try {
            const imageUrl = _this.uploadToServer
              ? await imgUpload(
                base64Url.substring(base64Url.indexOf(',') + 1),
                fileType,
                { type: calculateImageType(_this.width) }
              )
              : base64Url
            _this.setSectionImage(imageUrl)
          } catch (e) {
            _this.$store.errorNotification = e
            _this.uploading = false
          }
        })(this)
      imgforSizeDetect.src = _URL.createObjectURL(file)
    },
    setSectionImage (imageUrl) {
      this.$emit('change', imageUrl)
      this.currentImageUrl = imageUrl
    }
  }
}

const composeErrorMessage = ({ minWidth, maxWidth, minHeight, maxHeight }) => {
  let widthMessage = 'unlimited'
  let heightMessage = 'unlimited'
  if (minWidth) widthMessage = `minimum ${minWidth}px`
  if (maxWidth) widthMessage = `maximum ${maxWidth}px`
  if (minWidth && maxWidth) widthMessage = `${minWidth} ~ ${maxWidth}px`
  if (minHeight) heightMessage = `minimum ${minHeight}px`
  if (maxHeight) heightMessage = `maximum ${maxHeight}px`
  if (minHeight && maxHeight) heightMessage = `${minHeight} ~ ${maxHeight}px`
  return `Image size should be within range (Width: ${widthMessage} and Height: ${heightMessage})`
}

const calculateImageType = width => {
  if (width <= 200) {
    return 'width200'
  }
  if (width <= 300) {
    return 'width300'
  }
  if (width <= 600) {
    return 'width600'
  }
  return ''
}
</script>

<style lang="stylus" scoped>
$defaultHeight = 288px;

.dvupimg {
  text-align: center;
  img {
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  .lblUploading {
    position: fixed;
    background: rgba($black, 0.8);
    color: $white;
    padding: 15px 15px 0;
    border-radius: 4px;
    top: 50vh;
    left: 50%;
    z-index: 3;
    line-height: 20px;
  }
}

.activeIcon {
  .fileinputContainer {
    position: relative;
    display: inline-block;

    input {
      top: -8px;
      position: absolute;
      left: 0px;
      opacity: 0;
      z-index: 3;
      width: 35px;
      height: 40px;
      cursor: pointer;
    }
  }
}
</style>
