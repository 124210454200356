<template>
  <tr class="header">
    <td>
      <table
        width="600"
        v-html="headerHtml"
      />
    </td>
  </tr>
</template>

<script>
import { getHeaderHtml } from '../const/sectionHtml'

export default {
  name: 'SectionHeader',
  data () {
    return {
      headerHtml: getHeaderHtml({ isPreview: true })
    }
  }
}
</script>

<style lang="stylus" scoped>
.header {
  line-height: 1;
}
</style>
