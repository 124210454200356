class Selection {
  constructor (el, binding, vnode, oldVnode) {
    this.selection = { path: [] }
    this.selecting = false
    this.el = el
    this.binding = binding
    this.vnode = vnode
    this.oldVnode = oldVnode

    this.init()
  }

  init () {
    this.addListeners()
  }

  destroy () {
    this.removeListeners()
  }

  addListeners () {
    this.el.addEventListener('mousedown', this.startSelection.bind(this))
    this.el.addEventListener('mouseup', this.endSelection.bind(this))
    this.el.addEventListener('keydown', this.startSelection.bind(this))
    this.el.addEventListener('keyup', this.endSelection.bind(this))
    this.el.addEventListener('insertNode', this.insertNode.bind(this))
    this.el.addEventListener('updateNode', this.updateNode.bind(this))
  }

  removeListeners () {
    this.el.removeEventListener('mousedown', this.startSelection.bind(this))
    this.el.removeEventListener('mouseup', this.endSelection.bind(this))
    this.el.removeEventListener('keydown', this.startSelection.bind(this))
    this.el.removeEventListener('keyup', this.endSelection.bind(this))
    this.el.removeEventListener('insertNode', this.insertNode.bind(this))
    this.el.removeEventListener('updateNode', this.updateNode.bind(this))
  }

  emitSelected (event, selection) {
    this.el.dispatchEvent(
      new CustomEvent('selected', { detail: { event, selection } })
    )
  }

  genNode (tag, child, attributes = {}) {
    const node = document.createElement(tag)
    node.innerHTML = child
    Object.entries(attributes).forEach(([key, value]) => {
      node.setAttribute(key, value)
    })
    return node
  }

  updateNode (e) {
    const { detail } = e
    if (detail == null) {
      this.flattenNode()
      return
    }
    const { tag, child, attrs = {} } = detail

    const scope = this.findNode(this.selection.path)
    const target = scope.parentNode
    const content = child || target.innerHTML
    const node = this.genNode(tag, content, attrs)
    target.parentNode.replaceChild(node, target)

    this.emitChangeEvents()
  }

  insertNode (e) {
    const { detail } = e
    const { tag, child, attrs = {} } = detail
    const scope = this.findNode(this.selection.path)

    const content = child || this.selection.text
    const node = this.genNode(tag, content, attrs)
    const range = document.createRange()
    range.setStart(scope, this.selection.start)
    range.setEnd(scope, this.selection.end)
    range.deleteContents()
    range.insertNode(node)

    this.emitChangeEvents()
  }

  emitChangeEvents () {
    this.el.dispatchEvent(new Event('input'))
    this.el.dispatchEvent(new Event('change'))
  }

  flattenNode () {
    const node = this.findNode(this.selection.path)
    node.parentNode.outerHTML = node.parentNode.innerHTML
  }

  clearSelection () {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges()
    }
  }

  startSelection () {
    this.selecting = true
  }

  endSelection (e) {
    if (!this.selecting) return
    this.selecting = false

    const selection = window.getSelection()
    this.selection = {
      start: Math.min(selection.anchorOffset, selection.focusOffset),
      end: Math.max(selection.anchorOffset, selection.focusOffset),
      path: this.getPath(selection.anchorNode),
      text: selection.toString()
    }

    this.emitSelected(e, selection)
  }

  getPath (node) {
    if (node.isEqualNode(this.el)) {
      return []
    }
    const parent = node.parentNode
    return this.getPath(parent).concat(
      Array.from(parent.childNodes).indexOf(node)
    )
  }

  findNode (path = []) {
    let node = this.el
    for (const idx of path) {
      node = node.childNodes[idx]
    }
    return node
  }
}

const directive = (el, ...rest) => {
  el._selection = new Selection(el, ...rest)
}

const unbind = el => {
  el._selection.destroy()
  delete el._selection
}

export default {
  bind: directive,
  unbind
}
