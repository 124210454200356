<template lang="pug">
  .container(:class="{ 'has-error': this.errorMessage }")
    .header
      .label(v-if='label')
        | {{ label }}
        span.star(v-if='required')
          |
          | *
      x-menu.insert-btn(
        v-if="dropdowns"
        :menuList="dropdownOptions"
      )
        v-btn.btnSecondary(
          slot='trigger'
          small flat
        )
          | Insert
          v-icon
            | keyboard_arrow_down
    .input(v-bind="{ ...$attrs }" :class="{ focus: isFocus }")
      template(v-if='!html')
        .placeholder.child
          | {{ placeholder }}
      //-
        Re-render would cause input cursor jumpping to the beginning everytime
        content changed. Thus adding v-once to prevent re-render.
        Thus the `value` prop must not be updated from outside.
      .content.child(
        ref="html"
        v-once
        v-selection
        contenteditable=true
        @selected="showTools"
        v-html="html"
        @input="onInput"
        @blur="handleBlur"
        @focus="handleFocus"
      )
    .v-text-field__details
      .v-messages.theme--light.error-message
        .v-messages__wrapper
          .v-messages__message
            | {{ errorMessage }}
      .v-counter.theme--light(:class="{ 'error--text': !isCounterValid }")
        | {{ counterMessage }}
    ShortenUrlDialog(v-model="tools.shortenUrl.isDialogShow")
    VariableDialog(v-model="tools.variable.isDialogShow" @change="insertVariable")
    VariableSelector(
      :selected.sync="selectedVariable"
      :value="tools.variable.isShow"
      :position="tools.variable.position"
    )
    LucidSurveyDialog(v-model="tools.lucidSurvey.isDialogShow" :default="tools.lucidSurvey.sid" @change="insertLucid")
    x-button.activeIcon(
      v-if="tools.lucidSurvey.isShow"
      v-bind="buttonEdmLinkto"
      :style="{ position: 'absolute', top: `${tools.lucidSurvey.position.y}px`, left: `${tools.lucidSurvey.position.x}px` }"
      :click="() => tools.lucidSurvey.isDialogShow = true"
    )
    SurveyUrlDialog(v-model="tools.surveyUrl.isDialogShow" @change="insertShortSurveyUrl")

</template>

<script>
import { notEmpty, maxLength } from '@/utils/validation'
import VariableDialog from './VariableDialog'
import ShortenUrlDialog from './ShortenUrlDialog'
import SurveyUrlDialog from './SurveyUrlDialog'
import LucidSurveyDialog from './LucidSurveyDialog'
import { buttonEdmLinkto } from '@/components/SectionUI/const/sections'
import VariableSelector from '@/components/SectionUI/tools/VariableSelector'
import selection from '@/directives/selection'
import { VARIABLES, TEMPLATE_KEYS } from '@/const'
import DOMPurify from 'dompurify'

const decodeHTML = html => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export default {
  components: {
    VariableSelector,
    VariableDialog,
    ShortenUrlDialog,
    LucidSurveyDialog,
    SurveyUrlDialog
  },
  directives: { selection },
  props: {
    label: { type: String, default: '' },
    value: { type: String, required: true },
    placeholder: { type: String, default: '' },
    errorMessages: { type: String, default: '' },
    required: { type: Boolean, default: false },
    counter: { type: Number, default: null },
    rules: { type: Array, default: () => [] },
    dropdowns: {
      type: Array,
      validator (x) {
        return x.every(x =>
          ['shortenUrl', 'variable', 'lucidSurvey', 'surveyUrl'].includes(x)
        )
      },
      default: null
    },
    form: { type: Object, default: null }
  },
  data () {
    return {
      isFocus: false,
      html: this.parseToHtml(this.value),
      errorMessage: null,
      tools: {
        shortenUrl: {
          isDialogShow: false
        },
        variable: {
          isDialogShow: false,
          isEdit: false,
          isShow: false,
          selected: null,
          position: {
            x: 0,
            y: 0
          }
        },
        lucidSurvey: {
          isDialogShow: false,
          isEdit: false,
          isShow: false,
          sid: null,
          position: {
            x: 0,
            y: 0
          }
        },
        surveyUrl: {
          isDialogShow: false,
          isEdit: false,
          isShow: false,
          url: null,
          position: {
            x: 0,
            y: 0
          }
        }
      },
      dropdownMap: {
        shortenUrl: {
          name: 'Shorten URL',
          click: () => {
            this.tools.shortenUrl.isDialogShow = true
          }
        },
        variable: {
          name: 'Variables',
          click: () => {
            this.tools.variable.isDialogShow = true
            this.tools.variable.isEdit = false
          }
        },
        lucidSurvey: {
          name: 'Lucid Survey',
          click: () => {
            this.tools.lucidSurvey.isDialogShow = true
            this.tools.lucidSurvey.isEdit = false
            this.tools.lucidSurvey.sid = null
          }
        },
        surveyUrl: {
          name: 'Survey URL',
          click: () => {
            this.tools.surveyUrl.isDialogShow = true
            this.tools.surveyUrl.isEdit = false
            this.tools.surveyUrl.url = null
          }
        }
      },
      buttonEdmLinkto
    }
  },
  computed: {
    internalRules () {
      const rules = this.rules.slice()
      if (this.counter !== null) {
        rules.push(maxLength(this.counter))
      }
      if (this.required) {
        rules.push(notEmpty)
      }
      return rules
    },
    dropdownOptions () {
      return { list: this.dropdowns.map(x => this.dropdownMap[x]) }
    },
    counterMessage () {
      if (this.counter === null) {
        return null
      }
      const text = this.getInnerText(this.html)
      return `${text.length} / ${this.counter}`
    },
    isCounterValid () {
      if (this.counterMessage === null) {
        return true
      }
      const match = this.counterMessage.match(/^(.+)\s\/\s(.+)$/)
      return Number(match[1]) <= Number(match[2])
    },
    selectedVariable: {
      get () {
        return this.tools.variable.selected
      },
      set (x) {
        this.insertVariable(x)
      }
    }
  },
  watch: {
    errorMessages: {
      immediate: true,
      handler (val) {
        this.errorMessage = val || this.errorMessage
      }
    },
    html: 'validate',
    form: {
      immediate: true,
      handler: 'registerToForm'
    }
  },
  methods: {
    registerToForm () {
      /**
       * `register` is a private API of VForm of vuetify 1.5.16.
       */
      if (!(this.form && typeof this.form.register === 'function')) return
      this.form.register(this)
    },
    validate () {
      const html = this.getInnerText(this.html)
      for (const rule of this.internalRules) {
        const res = rule(html)
        if (typeof res === 'string' || res === false) {
          this.errorMessage = res
          return false
        }
      }
      this.errorMessage = null
      return true
    },
    handleFocus () {
      this.isFocus = true
    },
    handleBlur () {
      this.emitChange()
      this.validate()
      this.isFocus = false
    },
    onInput (e) {
      this.html = DOMPurify.sanitize(e.target.innerHTML, {
        ALLOWED_ATTR: ['contenteditable'],
        ALLOWED_TAGS: ['span', 'br', 'div']
      })
      this.emitChange()
    },
    emitChange (e) {
      this.$emit('input', this.getInnerText(this.html))
    },
    parseToHtml (text) {
      let s = text
      for (const variable of VARIABLES) {
        s = this.replaceKeywordWithHtml(s, variable, 'variable')
      }
      s = this.replaceKeywordWithHtml(
        s,
        `${TEMPLATE_KEYS.LUCID_SID}:.+?`,
        'lucidSurvey'
      )
      s = s.replace(/\n/g, '<br>')
      return s
    },
    replaceKeywordWithHtml (str, keyword, type) {
      const regex = new RegExp(`({{${keyword}}})`, 'g')
      str = str.replace(
        regex,
        `<span contenteditable="false" data-type="${type}">$1</span>`
      )
      return str
    },
    getInnerText (html) {
      const purified = DOMPurify.sanitize(
        html.replace(/<br>/g, '\n').replace(/<div>(.+?)<\/div>/g, '\n$1'),
        {
          ALLOWED_ATTR: [],
          ALLOWED_TAGS: []
        }
      )

      return decodeHTML(purified)
    },
    showTools (e) {
      const { event } = e.detail
      const type = event.target.dataset.type
      this.clearTools()
      switch (type) {
        case 'variable':
          return this.showVariableTool(e)
        case 'lucidSurvey':
          return this.showLucidSurveyTool(e)
        case 'surveyUrl':
          return this.showSurveyUrlTool(e)
        default:
      }
    },
    clearTools () {
      this.tools.variable.isShow = false
      this.tools.lucidSurvey.isShow = false
      this.tools.surveyUrl.isShow = false
    },
    showVariableTool (e) {
      this.showEditTool(e, 'variable')
    },
    showSurveyUrlTool (e) {
      const dom = e.detail.event.target
      const text = dom.innerText
      const reg = `{{${TEMPLATE_KEYS.SHORT_URL}:{{(${
        TEMPLATE_KEYS.SURVEY_URL
      }|${TEMPLATE_KEYS.SURVEY_URL_PAID}):(.+)}}}}`
      const match = text.match(new RegExp(reg))
      this.tools.surveyUrl.url = match && match[1]
      this.showEditTool(e, 'surveryUrl')
    },
    showLucidSurveyTool (e) {
      const dom = e.detail.event.target
      const text = dom.innerText
      const match = text.match(
        new RegExp(`{{${TEMPLATE_KEYS.LUCID_SID}:(.+)}}`)
      )
      this.tools.lucidSurvey.sid = match && match[1]
      this.showEditTool(e, 'lucidSurvey')
    },
    showEditTool (e, key) {
      const { event } = e.detail
      this.tools[key].isShow = true
      this.tools[key].isEdit = true
      this.tools[key].position = {
        x: event.target.offsetLeft,
        y: event.target.offsetTop - 15
      }
    },
    insertVariable (text) {
      this.insertKeyword({ text, type: 'variable' })
    },
    insertShortSurveyUrl (url, isPaid) {
      let text = `${TEMPLATE_KEYS.SHORT_URL}:`
      if (isPaid) {
        text += `{{${TEMPLATE_KEYS.SURVEY_URL_PAID}:${url}}}`
      } else {
        text += `{{${TEMPLATE_KEYS.SURVEY_URL}:${url}}}`
      }
      this.insertKeyword({
        type: 'surveyUrl',
        text
      })
    },
    insertLucid (sid) {
      this.insertKeyword({
        text: `${TEMPLATE_KEYS.SHORT_URL}:{{${
          TEMPLATE_KEYS.LUCID_SID
        }:${sid}}}`,
        type: 'lucidSurvey'
      })
    },
    insertKeyword ({ text, type }) {
      const detail = {
        tag: 'SPAN',
        child: `{{${text}}}`,
        attrs: {
          contenteditable: false,
          'data-type': type
        }
      }
      const keyword = this.tools[type].isEdit ? 'updateNode' : 'insertNode'
      this.$refs.html.dispatchEvent(new CustomEvent(keyword, { detail }))
    }
  }
}
</script>

<style lang="stylus" scoped>
$dark-gray = #525862;
$gray = #d3d9db;
$red = #fb3939;
$blue = #48b1e4;

.container {
  background: unset;
  padding: 0;
  margin-bottom: 4px;
  .has-error {
    .input {
      color: $red;
      caret-color: $red;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  >>> .v-btn {
    margin: 0px;
    padding: 0px;
  }
}
.label {
  font-weight: 600;
  color: $dark-gray;
  .star {
    color: $red;
  }
}
.input {
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid $gray;
  min-height: 100px;
  border-radius: 3px;
  margin-bottom: 2px;
  color: $blue;

  .child {
    width: 100%;
    min-height: 100px;
    padding: 20px;
  }
  .placeholder {
    user-select: none;
    color: $gray;
    position: absolute;
    top: 0;
    pointer-events: none;
  }
  .content {
    outline: none;
    color: $dark-gray;
  }
  &:after {
    content: '';
    display: block;
    border: 1px solid currentColor;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    transform: scaleX(0);
  }
  &.focus:after {
    transform: scaleX(1);
  }
}
.error-message {
  height: 20px;
  font-size: 14px;
  color: $red !important;
  caret-color: $red !important;
}
</style>
