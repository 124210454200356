<template lang="pug">
  Form(v-if="template" :template="template" @submit="editTemplate")
</template>

<script>
import Form from '../components/AppPushForm'

export default {
  components: {
    Form
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      template: null
    }
  },
  created () {
    this.fetchTemplate()
  },
  methods: {
    async fetchTemplate () {
      try {
        const {
          data: { templates }
        } = await this.$Template.fetchTemplate({
          templateId: this.id
        })
        this.template = templates[0]
        if (this.template.taskCount !== 0) {
          throw this.$t('Template.cannot_be_edited')
        }
      } catch (e) {
        this.$store.errorNotification = e
        this.$router.replace({ name: 'templateList' })
      }
      this.$store.loading = false
    },
    async editTemplate (data) {
      this.$store.loading = true
      try {
        await this.$Template.updateTemplate(data)
        this.$store.successNotification = 'Template successfully updated'

        this.$router.go(-1)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>
