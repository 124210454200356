<template>
  <div
    class="activeIcon"
    :style="style"
  >
    <v-menu open-on-click>
      <x-button
        slot="activator"
        ref="activator"
        v-bind="buttonDropdown"
        class="btnDropdown"
      />
      <v-list
        dark
        dense
        class="dropdown-list"
      >
        <v-list-tile
          v-for="item of variables"
          :key="item"
          :class="{ active: item === selected }"
          @click="() => $emit('update:selected', item)"
        >
          <v-list-tile-content>
            {{ $t(`Template.variable.${item}`) }}
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { VARIABLES } from '@/const'

export default {
  name: 'VariableSelector',
  props: {
    selected: {
      type: String,
      default: ''
    },
    position: {
      type: Object,
      default: () => ({ display: 'none' })
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      variables: VARIABLES,
      buttonDropdown: {
        content: 'Variables',
        appendIcon: 'keyboard_arrow_down',
        borderRadius: 4,
        color: '#000',
        size: 'small'
      }
    }
  },
  computed: {
    style () {
      return {
        display: this.value ? 'block' : 'none',
        top: this.position.y + 'px',
        left: this.position.x + 'px'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-list {
  background-color: $black-imp;
  color: rgba($white, 0.6) !important;
  border-radius: 4px

    > .active >>> .theme--dark {
      background-color: rgba($white, 0.3);

        div {
          font-weight: bold;
          color: $white-imp
        }
    }

  &.theme--dark {
    >>> .v-list__tile {
      &:hover {
        background-color: rgba($white, 0.2);
        color: $white-imp
      }
    }
  }
}
</style>
