<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="actionBtnWrap"
    >
      <h2 class="headline font-weight-medium">
        EDM Editor
      </h2>
      <v-spacer class="hidden-xs-only" />
      <x-formgroup
        v-model="scheme.valid"
        class="mb-3"
      >
        <v-layout class="actionBtnGroup">
          <x-button
            v-bind="scheme.buttonSendTest"
            class="btnPrimary"
          />
          <x-button
            v-bind="scheme.buttonCancel"
            class="btnInfo"
          />
          <x-button
            v-bind="scheme.buttonPreview"
            class="btnSecondary"
          />
          <x-button
            v-bind="scheme.buttonSave"
            class="btnSecondary"
          />
        </v-layout>
      </x-formgroup>
    </v-layout>
    <!-- END header -->

    <hr>

    <!-- BEGIN input group -->
    <v-layout
      row
      wrap
      class="edmInfo mt-3 mb-3"
    >
      <v-flex
        lg12
        class="pt-1"
      >
        <x-formgroup
          ref="form"
          v-model="scheme.valid"
          class="w100"
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md6
            >
              <x-textfield
                v-model="scheme.templateName.value"
                v-bind="scheme.templateName"
                class="w100 template-name"
              />
            </v-flex>
            <v-flex
              xs12
              md6
            >
              <x-textfield
                v-model="scheme.emailSubject.value"
                v-bind="scheme.emailSubject"
                class="w100 mr-0"
              />
            </v-flex>
          </v-layout>
        </x-formgroup>
      </v-flex>
    </v-layout>
    <!-- END input group -->

    <!-- BEGIN content -->
    <v-layout>
      <!-- BEGIN editor area -->
      <v-flex
        lg9
        md10
        class="edmEditorWrap"
      >
        <div class="edmEditorLabel body-2">
          editor area
        </div>

        <div
          class="editorArea"
          @dragover="onDragOver($event)"
          @dragenter="onDragEnter($event)"
        >
          <edm-section-container
            v-if="$store.builderProperty.rowSectionList.length !== 0"
          />
        </div>
      </v-flex>
      <!-- END editor area -->

      <!-- BEGIN aside panel -->
      <v-flex
        lg3
        md2
        class="minWidthAuto asidePanelWrap"
      >
        <div class="asidePanel">
          <p class="tipsWrap body-2">
            <img
              src="@/assets/dragicon.svg"
              class="dragIcon"
            >Drag-and-drop to
            switch up your contents
          </p>
          <div class="cardWrap">
            <v-card
              v-for="(data, index) in company.edmComponents"
              :key="index"
              class="pa-0"
            >
              <v-img
                :src="data.cover"
                :data-type="data.type"
                contain
                draggable="true"
                @dragstart="onDragStart($event)"
              />
            </v-card>
          </div>
        </div>
      </v-flex>
      <!-- END aside panel -->
    </v-layout>
    <!-- END content -->

    <edm-preview
      v-model="dialogEdmPreview.value"
      :subject="scheme.emailSubject.value"
      class="displayInline"
      @confirm="dialogEdmPreview.value = false"
    />

    <sending-test
      v-if="$store.builderProperty.rowSectionList"
      v-model="showDialogSendingTest"
      type="EDM"
      :template-subject="scheme.emailSubject.value"
      :template-content="JSON.stringify($store.builderProperty.rowSectionList)"
      :template-id="$route.params.id || 'None'"
    />
    <!-- END dialog -->
  </div>
</template>

<script>
import {
  sectionType,
  rowSectionObj,
  builderEvent
} from '@/components/SectionUI/const/sections'
import SendingTest from '@/modules/Task/components/SendingTest'
import EdmSectionContainer from '@/components/SectionUI/EdmSectionContainer'
import EdmPreview from '../components/EdmPreview'
import { notEmpty, maxLength } from '@/utils/validation'
import company from '@/mixins/company'

export default {
  components: {
    EdmSectionContainer,
    EdmPreview,
    SendingTest
  },
  mixins: [company],
  data () {
    return {
      scheme: {
        buttonSendTest: {
          content: 'Sending Test',
          size: 'medium',
          outline: true,
          prependIcon: 'send',
          click: () => {
            this.showDialogSendingTest = true
          }
        },
        buttonPreview: {
          content: 'Preview',
          size: 'medium',
          outline: true,
          click: this.openEdmPreview
        },
        buttonSave: {
          content: 'Save',
          size: 'medium',
          click: this.onSaveHandle
        },
        buttonCancel: {
          content: 'Cancel',
          size: 'medium',
          outline: true,
          click: () => this.$router.go(-1)
        },
        templateName: {
          label: 'Template Name',
          type: 'text',
          placeholder: 'maximum length 50 characters',
          required: true,
          rules: [notEmpty, maxLength(50)].map(x => x.bind(this)),
          value: ''
        },
        emailSubject: {
          label: 'Email Subject',
          type: 'text',
          placeholder: 'maximum length 80 characters',
          required: true,
          rules: [notEmpty, maxLength(80)].map(x => x.bind(this)),
          value: ''
        }
      },
      dialogEdmPreview: {
        value: false
      },
      showDialogSendingTest: false
    }
  },
  async created () {
    await this.fetchTemplate()
  },
  methods: {
    onDragStart: function (event) {
      event.dataTransfer.setData('type', event.target.dataset.type)
    },
    onDragEnter: function (event) {
      event.preventDefault()
    },
    onDragOver: function (event) {
      event.preventDefault()
    },
    async onSaveHandle (event) {
      this.$root.$emit(builderEvent.sectionSaveContent)
      this.$store.loading = true
      try {
        if (!this.$refs.form.$children[0].validate()) {
          throw this.$t('validation.general')
        }
        const updateData = {
          id: this.$route.params.id,
          name: this.scheme.templateName.value,
          subject: this.scheme.emailSubject.value,
          content: JSON.stringify(this.$store.builderProperty.rowSectionList),
          type: 'EDM'
        }

        const {
          data: { message }
        } = this.$route.params.id
          ? await this.$Template.updateTemplate(updateData)
          : await this.$Template.createTemplate(updateData)
        if (message) {
          throw message
        }

        this.$store.builderProperty.rowSectionList = []
        this.$router.push({ name: 'TemplateList' })
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    async fetchTemplate () {
      if (this.$route.params.id) {
        this.$store.loading = true
        try {
          const {
            data: { templates }
          } = await this.$Template.fetchTemplate({
            templateId: this.$route.params.id
          })
          if (templates.length <= 0) {
            throw new Error(this.$t('Template.not_found'))
          }

          const template = templates[0]
          if (template.taskCount !== 0) {
            throw new Error(this.$t('Template.cannot_be_edited'))
          }

          this.$store.builderProperty.rowSectionList = JSON.parse(
            template.content
          )
          this.scheme.templateName.value = template.name
          this.scheme.emailSubject.value = template.subject
        } catch (e) {
          this.$store.errorNotification = e
          this.$router.replace({ name: 'templateList' })
        }
        this.$store.loading = false
      } else {
        this.$store.builderProperty.rowSectionList = [
          rowSectionObj(sectionType.HeaderElement),
          rowSectionObj(sectionType.TextElement),
          rowSectionObj(sectionType.ImageElement),
          rowSectionObj(sectionType.ButtonElement)
        ]
      }
    },
    openEdmPreview () {
      this.$root.$emit(builderEvent.sectionSaveContent)
      this.dialogEdmPreview.value = true
    }
  }
}
</script>

<style lang="stylus" scoped>
$header-height = 64px;
$container-padding = 24px;

.contentWholeWrap {
  height: 'calc(100vh - %s - %s * 2)' % ($header-height $container-padding) !important;
}

@media screen and (min-width: 768px) {
  .edmInfo {
    .template-name {
      margin-right: 8px;
    }
  }
}

// Right side panel
.asidePanelWrap {
  background: $black + 61%;
  box-shadow: 0px 2px 8px rgba($black, 0.25);
  height: "calc(100vh - %s)" % $header-height;
  overflow-y: auto;
  position: sticky;
  position: -webkit-sticky;
  top: -1 * $container-padding;

  .asidePanel {
    width: 100%;

    .tipsWrap {
      text-align: center;
      color: $white;
      padding: 5px 10px;
      background: $black + 61%;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 2;

      .dragIcon {
        position: relative;
        width: 25px;
        top: 7px;
      }
    }

    .cardWrap {
      .v-card {
        margin: 15px 35px;
        padding: 5px;
        cursor: move;
      }
    }
  }
}

// Left editor area
.edmEditorWrap {
  position: relative;
  border: $thin-solid;
  border-color: $light-grey;
  padding-bottom: 1px;
  background-color: $white;

  .edmEditorLabel {
    position: absolute;
    background: $silver;
    color: $white;
    padding: 1px 6px;
  }

  .editorArea {
    margin: 0 auto;
  }
}
</style>
