<template lang="pug">
  .contentWholeWrap
    v-layout.mb-4(row wrap)
      h2.headline.font-weight-medium
        | App Push Editor
    .contentBoxWrap
      .contentBox
        v-layout
          v-flex(md6 offset-md3)
            x-formgroup(
              ref='form'
              v-model='scheme.valid'
              @submit.prevent='() => $refs.submitButton.click()'
            )
              .pa-2
                x-textfield.mb-1(
                  v-model='form.name'
                  v-bind='scheme.templateName'
                )
                x-textfield.mb-1(
                  v-model='form.content.title'
                  v-bind='scheme.templateTitle'
                )
                .position-relative
                  InputField(
                    v-model='form.content.content'
                    v-bind="scheme.textareaScheme"
                    :form="formRef"
                  )
                // We'll implement this feature after APPs are ready
                // .images-upload
                  label.labelStyle
                    | Image Upload
                    span.reminder
                      | (Recommended 500KB PNG or JPG)
                  .images-optional
                    .img-preview-box
                      img(
                        v-if='form.content.imageUrl'
                        :src='form.content.imageUrl'
                      )
                      img(v-else src='~assets/edmTemplate/empty-image.jpg')
                    div
                      .btn-upload
                        UploadImage(type='width600' @change='form.content.imageUrl = $event')
                          label
                            .default-text
                              i.material-icons
                                | cloud_upload
                              | UPLOAD
                      x-button.btnInfo(
                        v-bind='scheme.removeBtn'
                      )
                x-select-filter(
                  v-model='form.content.link'
                  v-bind='scheme.destination'
                  :items='deepLinkOptions'
                )
              v-layout.mt-3.actionBtnGroup
                x-button.btnInfo(v-bind='scheme.buttonCancel')
                x-button.btnSecondary(
                  ref='submitButton'
                  v-bind='scheme.buttonSubmit'
                )
                // v-spacer
                // x-button.btnPrimary(v-bind='scheme.buttonSendTest')
    // sending-test(
      v-model='showDialogSendingTest'
      type='APP_PUSH'
      :template-content='JSON.stringify(form.content)'
      :template-id="template.id || 'None'"
      )

</template>

<script>
import UploadImage from '@/components/UploadImage'
import InputField from '@/components/InputFieldWithVariable'
import { notEmpty, maxLength } from '@/utils/validation'
import SendingTest from '@/modules/Task/components/SendingTest'

export default {
  components: {
    SendingTest,
    UploadImage,
    InputField
  },
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formRef: null,
      form: {
        ...this.template,
        type: 'APP_PUSH',
        content: this.template.content
          ? JSON.parse(this.template.content)
          : {
            title: '',
            content: '',
            link: null,
            imageUrl: null
          }
      },
      // showDialogSendingTest: false,
      deepLinks: {},

      scheme: {
        templateName: {
          label: 'Template Name',
          type: 'text',
          placeholder: 'maximum length 50 characters',
          required: true,
          rules: [notEmpty, maxLength(50)].map(x => x.bind(this))
        },
        templateTitle: {
          label: 'App Push Title',
          type: 'text',
          placeholder: 'maximum length 20 characters',
          required: true,
          rules: [notEmpty, maxLength(20)].map(x => x.bind(this))
        },
        textareaScheme: {
          label: 'App Push Content',
          name: 'apppushcontent',
          placeholder: 'maximum length 100 characters',
          required: true,
          counter: 100,
          rules: [notEmpty, maxLength(100)].map(x => x.bind(this)),
          dropdowns: ['variable']
        },
        destination: {
          label: 'Destination',
          placeholder: 'please select',
          required: true
        },
        buttonCancel: {
          content: 'Cancel',
          size: 'large',
          outline: true,
          click: () => {
            this.$router.go(-1)
          }
        },
        buttonSubmit: {
          content: 'Submit',
          size: 'large',
          click: this.submit
        },
        /*
        buttonSendTest: {
          content: 'Sending Test',
          size: 'medium',
          outline: true,
          prependIcon: 'send',
          click: () => {
            this.showDialogSendingTest = true
          }
        },
        */
        removeBtn: {
          content: 'Remove',
          outline: true,
          block: true,
          prependIcon: 'delete_outline',
          click: () => {
            this.form.content.imageUrl = null
          }
        }
      }
    }
  },
  computed: {
    deepLinkOptions () {
      return Object.entries(this.deepLinks).map(([key, value]) => ({
        text: this.$t(`Template.deepLink.${key}`),
        value
      }))
    }
  },
  created () {
    this.fetchDeepLinks()
  },
  mounted () {
    this.formRef = this.$refs.form.$children[0]
  },
  methods: {
    submit () {
      if (this.formRef.validate()) {
        return this.$emit('submit', {
          ...this.form,
          content: JSON.stringify(this.form.content)
        })
      }
    },
    async fetchDeepLinks () {
      try {
        const {
          data: { deepLinks }
        } = await this.$Template.fetchDeepLinks()
        this.deepLinks = deepLinks
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.images-upload {
  margin-bottom: 24px;
  .labelStyle {
    .reminder {
      color: $bluey-grey;
      font-weight: normal;
      padding-left: 6px;
    }
  }
  .images-optional {
    @media(min-width: 960px) {
      display: flex;
    }
    .img-preview-box {
      min-height: 80px;
      img {
        width: 100%;
      }
      @media(min-width: 960px) {
        margin-right: 16px;
      }
    }
    .btn-upload {
      flex: 0 1 190px;
      label {
        position: relative;
        cursor: pointer;
        text-align: center;
        color: #2c3542 !important;
        line-height: 40px;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1.2px;
        white-space: nowrap;
        padding: 0 30px;
        width: 100%;
        display: block;
        border: 1px solid $light-grey;
        border-radius: 4px;
        &:hover,
        &:focus {
          background: lighten($black,94%) !important;
        }
        i {
          font-size: 21px;
          position: relative;
          top: 6px;
          margin-right: 10px;
        }
        input[type="file"]{
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
.insert-btn {
  position: absolute;
  top: -14px;
  right: -8px;
}
</style>
