<template>
  <table
    class="contentBox edm-content-box"
    @dragleave.stop="() => {}"
  >
    <tr>
      <td>
        <table
          width="50%"
          border="0"
          cellpadding="0"
          cellspacing="0"
          class="columnImage"
          style="display: inline-block; vertical-align: middle;"
        >
          <tr>
            <td align="left">
              <item-image-upload
                :upload-to-server="true"
                :image-url="getImageUrl(0)"
                :section-id="handledSection.id"
                :min-width="295"
                :max-width="2000"
                :width="300"
                @change="url => updateImageUrl(0, url)"
              />
            </td>
          </tr>
        </table>

        <table
          width="50%"
          border="0"
          cellpadding="0"
          cellspacing="0"
          align="right"
          class="columnImage"
        >
          <tr>
            <td align="right">
              <item-image-upload
                :upload-to-server="true"
                :image-url="getImageUrl(1)"
                :section-id="handledSection.id"
                :min-width="295"
                :max-width="2000"
                :width="300"
                @change="url => updateImageUrl(1, url)"
              />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</template>

<script>
import { buttonEdmLinkto } from '@/components/SectionUI/const/sections'
import ItemImageUpload from '../ItemImageUpload'
import company from '@/mixins/company'

export default {
  name: 'Image2Column',
  components: { ItemImageUpload },
  mixins: [company],
  props: {
    handledSection: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      buttonEdmLinkto
    }
  },
  methods: {
    getImageUrl (ind) {
      return (
        (this.handledSection &&
          this.handledSection.data &&
          this.handledSection.data[ind] &&
          this.handledSection.data[ind].img) ||
        this.company.defaultImage.bannerSquare
      )
    },
    updateLink (url) {
      this.$store.builderProperty.rowSectionList.find(
        section => section.id === this.handledSection.id
      ).data.link.url = url
    },
    updateImageUrl (index, url) {
      const section = this.$store.builderProperty.rowSectionList.find(
        section => section.id === this.handledSection.id
      )
      section.data[index].img = url
    }
  }
}
</script>

<style lang="stylus" scoped>
.contentBox {
  td {
    line-height: 0;
    text-align: center;

    & >>> img {
      width: 294px;
      vertical-align: middle;
    }
  }

  .columnImage[align=right] {
    tr {
      width: 100%;
      text-align: right;
      display: inline-block;
      td {
        display: inline-block;
      }
    }
  }
}

.imgBox {
  width: 100%;
  border: 0;
  display: inline-block;
  vertical-align: middle;

  td {
    img {
      width: 220px;
    }
  }
}
</style>
