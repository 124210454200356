<template>
  <table
    class="contentBox edm-content-box"
    @dragleave.stop="() => {}"
  >
    <tr>
      <td>
        <a
          href="javascript:void(0);"
          target="_blank"
          @dragstart.prevent="() => {}"
        >
          <item-image-upload
            :upload-to-server="true"
            :image-url="imageUrl"
            :section-id="handledSection.id"
            :min-width="500"
            :max-width="2000"
            :width="600"
            @change="updateImage"
          >
            <span
              slot="actions"
              class="clickBtn"
              @click="openLinkConfigHandle"
            >
              <x-button
                v-bind="buttonEdmLinkto"
                class="btnLink"
              />
            </span>
          </item-image-upload>
        </a>
        <edit-link-dialog
          v-if="linkDialog.value"
          v-bind="linkDialog"
          @confirm="(_, link) => updateLink(link)"
          @close="linkDialog.value = false"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import { buttonEdmLinkto } from '@/components/SectionUI/const/sections'
import EditLinkDialog from '@/modules/Template/components/EditLinkDialog'
import ItemImageUpload from '../ItemImageUpload'
import company from '@/mixins/company'

export default {
  name: 'ImageFullWidth',
  components: {
    ItemImageUpload,
    EditLinkDialog
  },
  mixins: [company],
  props: {
    handledSection: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      linkDialog: {
        value: false,
        deletable: false
      },
      buttonEdmLinkto,
      image:
        this.handledSection &&
        this.handledSection.data &&
        this.handledSection.data.img
    }
  },
  computed: {
    imageUrl () {
      return this.image || this.company.defaultImage.banner
    }
  },
  methods: {
    openLinkConfigHandle () {
      const section = this.$store.builderProperty.rowSectionList.find(
        section => section.id === this.handledSection.id
      )
      this.linkDialog.value = true
      this.linkDialog.link = section.data.link
    },
    updateImage (imageUrl) {
      this.$store.builderProperty.rowSectionList.find(
        section => section.id === this.handledSection.id
      ).data.img = imageUrl
      this.image = imageUrl
    },
    updateLink (link) {
      this.$store.builderProperty.rowSectionList.find(
        section => section.id === this.handledSection.id
      ).data.link = link
    }
  }
}
</script>

<style lang="stylus" scoped>
.contentBox {
  padding-right: 0;
  padding-left: 0;

  td {
    text-align: center;

    & >>> img {
      width: 600px;
      vertical-align: middle;
    }
  }
}

.imgBox {
  width: 100%;
  border: 0;
  display: inline-block;
  vertical-align: middle;

  td {
    img {
      width: 220px;
    }
  }
}
</style>
