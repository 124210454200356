<template lang="pug">
  x-dialog(
    v-model="isShow"
    :cancel-text="null"
    :close-sign="true"
    confirm-text="SUBMIT"
    max-width="700px"
    @confirm="dialogConfirm"
    :class="$style.dialog"
  )
    h6.slot-header.title(slot="slot-header") Link to
    x-formgroup(
      ref="form"
      @submit.prevent="dialogConfirm"
    )
      v-radio-group(v-model='type')
        div(:class="$style['radio-item']")
          v-radio(
            label="URL"
            value="url"
          )
          x-textfield(
            v-if="type === 'url'"
            v-model="url"
            label="URL"
            :rules="[isUrl]"
            placeholder="Paste URL"
            :class="$style.content"
          )
        div(:class="$style['radio-item']")
          v-radio(
            label="Lucid Survey"
            value="lucid_marketplace"
          )
          x-textfield(
            v-if="type === 'lucid_marketplace'"
            v-model="sid"
            label="SID"
            :rules="[notEmpty]"
            :class="$style.content"
          )
        div(:class="$style['radio-item']")
          v-radio(
            label="Survey URL"
            value="surveyUrl"
          )
          x-textfield(
            v-if="type === 'surveyUrl'"
            v-model="surveyUrl"
            label="URL"
            :placeholder="'https://survey-company.com/survey1/?id={{userId}}'"
            :rules="[isUrl, hasUserIdQueryString]"
            :class="$style.content"
          )
          v-radio-group(
            v-if="type === 'surveyUrl'"
            v-model="isPaidSurvey"
            :class="$style.content"
            row
          )
            v-radio(
              key="Unpaid"
              label="Unpaid Campaign"
              :value="false"
            )
            v-radio(
              key="Paid"
              label="Paid Campaign"
              :value="true"
            )
      x-button(
        v-if="deletable"
        class="btnWarning ma-0"
        v-bind="buttonDelete"
      )
</template>

<script>
import { isUrl, notEmpty, hasUserIdQueryString } from '@/utils/validation'

export default {
  name: 'EditLinkDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      url: null,
      type: null,
      sid: null,
      isPaidSurveySurvey: false,
      surveyUrl: null,
      buttonDelete: {
        content: 'Remove Link',
        prependIcon: 'link_off',
        size: 'small',
        flat: true,
        click: this.dialogRemove
      }
    }
  },
  computed: {
    isShow: {
      get () {
        return this.value
      },
      set () {
        this.closeDialog()
      }
    }
  },
  mounted () {
    const {
      url = null,
      type = 'url',
      sid = null,
      surveyUrl = null,
      isPaidSurvey = false
    } = this.link
    this.url = url
    this.type = type
    this.sid = sid
    this.isPaidSurvey = isPaidSurvey
    this.surveyUrl = surveyUrl
  },
  methods: {
    notEmpty,
    isUrl,
    hasUserIdQueryString,
    validate () {
      if (!this.$refs.form.$children[0].validate()) {
        throw new Error('Some fields needs to be fixed')
      }
    },
    dialogConfirm (event) {
      try {
        this.validate()
        this.$emit('confirm', event, this.getLinkObject())
        this.closeDialog()
      } catch (e) {
        this.$store.errorNotification = e
      }
    },
    dialogRemove (event) {
      this.$emit('remove', event)
      this.closeDialog()
    },
    closeDialog () {
      this.$emit('close')
    },
    getLinkObject () {
      switch (this.type) {
        case 'lucid_marketplace':
          return {
            type: this.type,
            sid: this.sid
          }
        case 'url':
          return {
            type: this.type,
            url: this.url
          }
        case 'surveyUrl':
          return {
            type: this.type,
            surveyUrl: this.surveyUrl,
            isPaidSurvey: this.isPaidSurvey
          }
        default:
          throw new Error(`Invalid selection: ${this.type}`)
      }
    }
  }
}
</script>

<style lang="stylus" module>
.dialog {
  :global {
    .v-dialog {
      .slot-header {
        padding: 20px 50px 0 20px;
      }
    }
  }
}

.radio-item {
  .content {
    margin-left: 30px;
  }
}
</style>
