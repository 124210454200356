<template>
  <div>
    <x-formgroup
      ref="form"
      class="w100 originalUrl"
      @submit.prevent="() => $refs.button.click()"
    >
      <x-textfield
        v-model="longLink"
        class="layout flex sm12 column"
        type="text"
        label="Original URL"
        name="shorturl"
        :rules="longLinkValidation"
        placeholder="paste original URL"
      />
      <x-button
        ref="button"
        content="Shorten"
        size="large"
        :outline="true"
        class="btnPrimary btnXsBlock"
        :click="shorten"
      />
    </x-formgroup>
    <div :class="{ 'short-link': true, expand: !!shortLink }">
      <label class="labelStyle d-block mt-3 mb-2">Your Shorten URL</label>
      <div class="addedMobileNumber bgPaleBlue pa-3">
        <span class="pl-1">{{ shortLink }}</span>

        <x-button
          v-bind="buttonCopy"
          class="btnPrimary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { notEmpty, isUrl } from '@/utils/validation'

export default {
  data () {
    return {
      longLink: '',
      shortLink: '',
      longLinkValidation: [notEmpty, isUrl].map(x => x.bind(this)),
      buttonCopy: {
        content: 'file_copy',
        icon: true,
        round: true,
        size: 'small',
        click: () => {
          copyToClipboard(this.shortLink)
          this.$store.successNotification =
            'Short link has been copied to clipboard'
        },
        tooltip: {
          direction: 'top',
          content: 'Copy URL',
          contentClass: 'tooltipClass'
        }
      }
    }
  },
  methods: {
    validate () {
      if (!this.$refs.form.$children[0].validate()) {
        throw new Error('Some fields needs to be fixed')
      }
    },
    async shorten () {
      this.$store.loading = true
      try {
        this.validate()
        const { data } = await this.$Template.createShortLink({
          longLink: this.longLink
        })
        if (!data || !data.shortLink) {
          throw new Error('Create short link failed')
        }
        this.shortLink = data.shortLink
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}

const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}
</script>

<style lang="stylus" scoped>
.short-link {
  height: 0;
  overflow: hidden;

  &.expand {
    transition: 0.3s;
    height: 130px;
  }
}

@media (min-width: 768px) {
  .dialog-box {
    .originalUrl {
      display: inline-flex;

      button {
        &.btnPrimary {
          margin-top: 21px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .short-link {
    &.expand {
      height: auto;
      min-height: 130px;
    }
  }
}
</style>
