<template lang="pug">
  x-dialog(
    :value='value'
    @input="emitValue"
    cancelText='CANCEL'
    confirmText='INSERT'
    maxWidth='600px'
    @confirm='handleConfirm'
  )
    h3.slot-header.title(
      slot='slot-header'
    )
      | Lucid Survey
    x-formgroup(ref='form')
      x-textfield(
        label='SID'
        v-model='sid'
        :rules='[notEmpty]'
      )
</template>

<script>
import { notEmpty } from '@/utils/validation'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    default: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      notEmpty: notEmpty.bind(this),
      sid: ''
    }
  },
  watch: {
    default: {
      immediate: true,
      handler (val) {
        this.sid = val
      }
    }
  },
  methods: {
    emitValue (val) {
      this.$emit('input', val)
    },
    validate () {
      return this.$refs.form.$children[0].validate()
    },
    handleConfirm () {
      if (!this.validate()) return
      this.emitValue(false)
      this.$emit('change', this.sid)
    }
  }
}
</script>
