<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        SMS Editor
      </h2>
    </v-layout>
    <!-- END header -->
    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout>
          <v-flex
            md6
            offset-md3
          >
            <x-formgroup
              ref="form"
              v-model="scheme.valid"
              class="w100"
              @submit.prevent="() => $refs.submitButton.click()"
            >
              <!-- BEGIN input group -->
              <div class="pa-2">
                <x-textfield
                  v-model="scheme.templateName.value"
                  v-bind="scheme.templateName"
                  class="mb-1"
                />
                <div class="position-relative">
                  <InputField
                    v-if="initiated"
                    v-model="scheme.textareaScheme.value"
                    v-bind="scheme.textareaScheme"
                    :form="formRef"
                  />
                </div>
              </div>
              <!-- END input group -->
              <!-- BEGIN button group -->
              <v-layout class="mt-3 actionBtnGroup">
                <x-button
                  v-bind="scheme.buttonCancel"
                  class="btnInfo"
                />
                <x-button
                  ref="submitButton"
                  v-bind="scheme.buttonSubmit"
                  class="btnSecondary"
                />
                <v-spacer />
                <x-button
                  v-bind="scheme.buttonSendTest"
                  :disabled="!scheme.textareaScheme.value"
                  class="btnPrimary"
                />
              </v-layout>
              <!-- END button group -->
            </x-formgroup>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <!-- END content -->

    <sending-test
      v-if="scheme.textareaScheme.value"
      v-model="showDialogSendingTest"
      type="SMS"
      :template-content="scheme.textareaScheme.value"
      :template-id="templateId || 'None'"
    />
  </div>
</template>

<script>
import { notEmpty, maxLength } from '@/utils/validation'
import InputField from '@/components/InputFieldWithVariable'
import SendingTest from '@/modules/Task/components/SendingTest'

export default {
  components: { SendingTest, InputField },
  props: {
    templateId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      template: {},
      scheme: {
        templateName: {
          label: 'Template Name',
          type: 'text',
          placeholder: 'maximum length 50 characters',
          required: true,
          value: '',
          rules: [notEmpty, maxLength(50)].map(x => x.bind(this))
        },
        textareaScheme: {
          label: 'SMS Content',
          name: 'smscontent',
          placeholder: 'maximum length 500 characters',
          required: true,
          value: '',
          counter: 500,
          dropdowns: ['shortenUrl', 'variable', 'lucidSurvey', 'surveyUrl'],
          rules: [notEmpty].map(x => x.bind(this))
        },
        buttonShortenUrl: {
          content: 'Shorten URL',
          size: 'small',
          flat: true,
          prependIcon: 'link'
        },
        buttonCancel: {
          content: 'Cancel',
          size: 'large',
          outline: true,
          click: () => {
            this.$router.go(-1)
          }
        },
        buttonSubmit: {
          content: 'Submit',
          size: 'large',
          click: this.submit
        },
        buttonSendTest: {
          content: 'Sending Test',
          size: 'medium',
          outline: true,
          prependIcon: 'send',
          click: () => {
            this.showDialogSendingTest = true
          }
        }
      },
      showDialogSendingTest: false,
      formRef: null,
      initiated: false
    }
  },
  async mounted () {
    this.formRef = this.$refs.form.$children[0]
    if (this.templateId) {
      this.$store.loading = true
      try {
        const {
          data: { templates }
        } = await this.$Template.fetchTemplate({
          templateId: this.templateId
        })
        this.template = templates[0]
        if (this.template.taskCount !== 0) {
          throw this.$t('Template.cannot_be_edited')
        }
        this.scheme.templateName.value = this.template.name
        this.scheme.textareaScheme.value = this.template.content
        this.$store.loading = false
      } catch (e) {
        this.$store.errorNotification = e
        this.$store.loading = false
        this.$router.replace({ name: 'templateList' })
      }
    }
    this.initiated = true
  },
  methods: {
    validate () {
      if (!this.formRef.validate()) {
        throw this.$t('Campaign.form.validation_failed_general')
      }
    },
    async submit () {
      this.$store.loading = true
      try {
        this.validate()
        if (this.templateId) {
          await this.$Template.updateTemplate({
            ...this.template,
            name: this.scheme.templateName.value,
            type: 'SMS',
            content: this.scheme.textareaScheme.value
          })
          this.$store.successNotification = 'SMS Template successfully updated'
        } else {
          await this.$Template.createTemplate({
            name: this.scheme.templateName.value,
            type: 'SMS',
            content: this.scheme.textareaScheme.value
          })
          this.$store.successNotification = 'SMS Template successfully created'
        }

        this.$router.go(-1)
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.insert-btn {
  position: absolute;
  top: -14px;
  right: -8px;
}
</style>
