<template>
  <x-dialog
    :value="value"
    close-sign
    cancel-text="CANCEL"
    confirm-text="INSERT"
    max-width="700px"
    @input="emitValue"
    @confirm="handleConfirm"
  >
    <h3
      slot="slot-header"
      class="slot-header title"
    >
      Survey URL
    </h3>
    <x-formgroup
      ref="form"
      class="w100 originalUrl"
      @submit.prevent="() => $refs.button.click()"
    >
      <x-textfield
        v-model="url"
        class="layout flex sm12 column"
        type="text"
        label="Survey URL"
        :rules="[notEmpty, isUrl, hasUserIdQueryString]"
        :placeholder="'https://survey-company.com/survey1/?id={{userId}}'"
      />
      <v-radio-group
        v-model="isPaid"
        row
      >
        <v-radio
          key="Unpaid"
          label="Unpaid Campaign"
          :value="false"
        />
        <v-radio
          key="Paid"
          label="Paid Campaign"
          :value="true"
        />
      </v-radio-group>
    </x-formgroup>
  </x-dialog>
</template>

<script>
import { notEmpty, isUrl, hasUserIdQueryString } from '@/utils/validation'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      url: '',
      isPaid: false
    }
  },
  methods: {
    isUrl,
    notEmpty,
    hasUserIdQueryString,
    emitValue (val) {
      this.$emit('input', val)
    },
    validate () {
      return this.$refs.form.$children[0].validate()
    },
    handleConfirm () {
      if (!this.validate()) return
      this.emitValue(false)
      this.$emit('change', this.url, this.isPaid)
    }
  }
}
</script>
