<template>
  <tr>
    <td>
      <table v-html="footerHtml" />
    </td>
  </tr>
</template>

<script>
import { getFooterHtml } from './const/sectionHtml'

export default {
  name: 'SectionFooter',
  data () {
    return {
      footerHtml: getFooterHtml({ isPreview: true })
    }
  }
}
</script>
