<template lang="pug">
  x-dialog(
    :value='value'
    @input="emitValue"
    cancelText='CANCEL'
    confirmText='INSERT'
    maxWidth='400px'
    @confirm='handleConfirm'
  )
    h3.slot-header.title(
      slot='slot-header'
    )
      | Variables
    x-formgroup
      x-radio(
        column
        :items="variableOptions"
        v-model='selectedVariable'
      )
</template>

<script>
import { VARIABLES } from '@/const'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selectedVariable: null
    }
  },
  computed: {
    variableOptions () {
      return VARIABLES.map(x => ({
        label: this.$t(`Template.variable.${x}`),
        value: x
      }))
    }
  },
  methods: {
    emitValue (val) {
      this.$emit('input', val)
    },
    handleConfirm () {
      this.emitValue(false)
      this.$emit('change', this.selectedVariable)
    }
  }
}
</script>
