<template>
  <div class="sectionIcon">
    <span
      class="button-event-wrapper"
      @dragstart="ondragStart"
    >
      <x-button
        v-bind="buttonMove"
        draggable="true"
        class="btnMove"
      />
    </span>

    <x-button
      v-bind="buttonDelete"
      :disabled="!canDeleteSection"
      class="btnDelete"
    />
  </div>
</template>

<script>
export default {
  name: 'FunctionBarSection',
  props: {
    handledSection: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      inEdit: false,
      buttonDelete: {
        content: 'delete',
        icon: true,
        borderRadius: 4,
        color: '#D0707B',
        size: 'small',
        click: this.deleteSectionIntent
      },
      buttonMove: {
        content: 'unfold_more',
        icon: true,
        borderRadius: 4,
        color: '#000',
        size: 'small'
      }
    }
  },
  computed: {
    canDeleteSection () {
      return this.$store.builderProperty.rowSectionList.length > 1
    }
  },
  methods: {
    onDeleteHandle: function (event) {
      if (!this.canDeleteSection) {
        return (this.$store.errorNotification = 'Cannot remove all sections')
      }
      const delIndex = this.$store.builderProperty.rowSectionList.findIndex(
        Obj => Obj.id === this.handledSection.id
      )
      this.$store.builderProperty.rowSectionList.splice(delIndex, 1)
    },
    ondragStart (event) {
      this.$store.builderProperty.draggingSectionId = this.handledSection.id
      event.dataTransfer.setData(
        'handledSection',
        JSON.stringify(this.handledSection)
      )
    },
    deleteSectionIntent (e) {
      if (!this.canDeleteSection) return false
      e.stopPropagation()
      this.$store.confirmDialog.push({
        message: 'Are you sure to remove this section?',
        onConfirm: () => this.onDeleteHandle(e)
      })
      return false
    }
  }
}
</script>

<style lang="stylus" scoped>
.button-event-wrapper {
  margin: 6px 8px 0px;
  display: inline-block;
  button {
    margin: 0;
  }
}
</style>
