<template>
  <table class="table-wrapper">
    <tr>
      <td>
        <table class="table-container">
          <!-- Header -->
          <section-header />

          <section-title
            :title-section="title"
            @update="onUpdate"
          />

          <tr>
            <td>
              <div class="sectionContainer">
                <table class="dragSection">
                  <DragHandle
                    v-for="data in sectionsList"
                    :key="data.id"
                    :section-obj="data"
                  />
                </table>
              </div>
            </td>
          </tr>

          <section-bottom />

          <section-footer />
        </table>
      </td>
    </tr>
    <!-- BEGIN dialog components -->
  </table>
</template>

<script>
import SectionHeader from './SectionItems/SectionHeader'
import SectionTitle from './SectionItems/SectionTitle'
import SectionBottom from './SectionItems/SectionBottom'
import SectionFooter from './SectionFooter'
import DragHandle from './DragHandle'
import {
  sectionType,
  generateSectionId,
  rowSectionObj
} from '@/components/SectionUI/const/sections'

export default {
  name: 'EdmSectionContainer',
  components: {
    SectionHeader,
    SectionTitle,
    SectionBottom,
    SectionFooter,
    DragHandle
  },

  data () {
    return {}
  },
  computed: {
    sectionsList () {
      return this.$store.builderProperty.rowSectionList
    },
    title () {
      const titleSection = this.$store.builderProperty.rowSectionList.find(
        section => section.type === sectionType.HeaderElement
      )
      // Note: Old Templates may not have TitleSection
      if (!titleSection) return rowSectionObj(sectionType.HeaderElement)
      if (!titleSection.id) titleSection.id = generateSectionId()
      return titleSection
    }
  },
  methods: {
    onUpdate (section = {}) {
      const ind = this.$store.builderProperty.rowSectionList.findIndex(
        s => s.id === section.id
      )
      if (ind >= 0) {
        this.$store.builderProperty.rowSectionList.splice(ind, 1, section)
      } else {
        // Note: Old Templates may not have TitleSection
        const insertIndex = section.type === sectionType.HeaderElement ? 0 : -1
        this.$store.builderProperty.rowSectionList.splice(
          insertIndex,
          0,
          section
        )
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.table-wrapper {
  width: 100%;
  border: 0;
  background-color: $white;
  padding: 30px 20px;
  margin-top: 1px;

  .table-container {
    width: 100%;
    max-width: 600px;
    border: 0px;
    margin: 0 auto;
    border-collapse: collapse;
    
    .sectionContainer {
      .dragSection {
        width: 100%;
        border-spacing: 0;
      }
    }
  }
}
</style>
