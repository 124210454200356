<template lang="pug">
  x-dialog(
    :value='value'
    @input="emitValue"
    :cancelText="null"
    :confirmText="null"
    closeSign
    maxWidth="700px"
    @confirm='() => emitValue(false)'
  )
    h3.slot-header.title(
      slot='slot-header'
    )
      | Shorten URL
    ShortenUrl
</template>

<script>
import ShortenUrl from '@/modules/Template/components/ShortenUrl'

export default {
  components: {
    ShortenUrl
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    emitValue (val) {
      this.$emit('input', val)
    }
  }
}
</script>
