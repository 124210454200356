const axios = require('axios')

export const convertFileToBase64 = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = error => reject(error)
  })
}

export const imgUpload = async (ImageBase64, extension = 'jpg', options) => {
  const res = await axios
    .post('/api/v1/images', {
      ...options,
      image: ImageBase64,
      extension
    })
    .then(response => response.data.url)

  const url = await new Promise(resolve => {
    const checkImage = tries => {
      const img = document.createElement('IMG')
      img.src = res
      img.onerror = () => {
        setTimeout(() => checkImage(tries - 1), 1000)
      }
      img.onload = () => {
        resolve(res)
      }
    }
    checkImage(3)
  })

  return url
}
