<template lang="pug">
  .wrapper
    slot
    input.input(
      type="file"
      :accept="accept"
      @change="onfileChange"
    )
</template>

<script>
import { convertFileToBase64, imgUpload } from '@/apis/Upload'

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator (type) {
        return ['width200', 'width300', 'width600'].includes(type)
      }
    },
    png: {
      type: Boolean,
      default: true
    },
    jpg: {
      type: Boolean,
      default: true
    },
    gif: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    accept () {
      const accepts = []
      if (this.png) accepts.push('image/png')
      if (this.jpg) accepts.push('image/jpeg')
      if (this.gif) accepts.push('image/gif')
      return accepts.join(', ')
    }
  },
  methods: {
    onfileChange (event) {
      const file = event.target.files[0]

      const imgforSizeDetect = new Image()
      const URL = window.URL || window.webkitURL

      imgforSizeDetect.onload = async () => {
        this.$store.loading = true
        const fileType = file.type.match(/^image\/(.+)$/)[1]
        const base64Url = await convertFileToBase64(file)
        const options = { type: this.type }
        try {
          const imageUrl = await imgUpload(
            base64Url.substring(base64Url.indexOf(',') + 1),
            fileType,
            options
          )
          this.emitChange(imageUrl)
        } catch (e) {
          this.$store.errorNotification = e
        }
        this.$store.loading = false
      }
      imgforSizeDetect.src = URL.createObjectURL(file)
    },
    emitChange (imageUrl) {
      this.$emit('change', imageUrl)
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper {
  position: relative;
}
.input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
